import { Link } from 'react-router-dom';
import { storages, DINAMICA_ABIERTA, dinamica_cerrada_copy, URL_TYCS } from '../core/statics';
import styled from 'styled-components';
import logo from '../assets/images/logo-intro.png';
import texto1 from '../assets/images/textos-intro/1.svg';
import texto2 from '../assets/images/textos-intro/2.svg';
import boton from '../assets/images/boton-intro.svg';
import canasta from '../assets/images/canasta-intro.png';
import botonNext from '../assets/images/boton-siguiente.svg';

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: space-around;
    min-height: 570px;
    overflow: hidden;

    a:not(.btn-next):not(.terms) {
        width: 100%;
        text-align: center;
    }
`;
const DivTop = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    margin: 20px auto;
    justify-items: center;
    svg {
        display: block;
        @media (max-width: 370px) {
            transform: scale(0.8);
            margin: -2px auto;
        }
    }
`;
const Logo = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;
const Texto1 = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;
const Texto2 = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;
const Boton = styled.img`
    max-width: 68vw;
    display: block;
    margin: 5px auto;
`;
const Canasta = styled.img`
    max-width: 68vw;
    display: block;
    margin: 5px auto;
`;
const BotonNext = styled.img`
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 15px 15px auto;
`;
const Footer = styled.div`
    width: 100%;
    position: relative;
    a.terms {
        display: block;
        margin: 0 auto;
        background: #df1d06;
        color: #fff;
        width: 227px;
        padding: 5px;
        text-align: center;
        font-weight: normal;
        font-style: normal;
        text-decoration: none;
        font-size: 18px;
        border-radius: 18px;
    }
    a.btn-next {
        position: absolute;
        right: 0;
        top: -5px;
    }
`;
const CerradaContainer = styled.div`
    padding: 10px;
    text-align: center;
    color: white;
`;

const Intro = () => {
    sessionStorage.setItem(storages.INTROSEEN, true);
    const termsLink = `https://docs.google.com/viewer?url=${encodeURIComponent(URL_TYCS)}`;

    const content = DINAMICA_ABIERTA()
        ? <>
            <DivTop>
                <Texto1 src={texto1} />
                <Logo src={logo} />
                <Texto2 src={texto2} />
            </DivTop>
            <Link to='/'>
                <Boton src={boton} />
            </Link>
            <Canasta src={canasta} />
            <Footer>
                <a className='terms' href={termsLink} rel='noreferrer' target='_blank'>
                    Términos y condiciones
                </a>
                <Link to='/' className='btn-next'>
                    <BotonNext src={botonNext} />
                </Link>
            </Footer>
        </>
        : <CerradaContainer>{dinamica_cerrada_copy}</CerradaContainer>;

    return (
        <Container>
            {content}
        </Container>
    );
};

export default Intro;

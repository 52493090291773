import React from 'react';
import styled from 'styled-components';
import titulo from '../assets/images/titulo-home.svg';
import LoginComponent from '../components/LoginComponent';
import { hasSession } from '../helpers';
import JuegaYganaTitle from '../components/JuegaYganaTitle';
import ContadorDespensas from '../components/ContadorDespensas';
import FooterLogo from '../components/FooterLogo';
import { DINAMICA_ABIERTA, dinamica_cerrada_copy } from "../core/statics";

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    min-height: 570px;
    overflow: hidden;

    a {
        width: 100%;
        text-align: center;
    }
`;
const Titulo = styled.img`
    width: auto;
    max-width: 90vw;
    display: block;
    margin: 5px auto;
`;
const CerradaContainer = styled.div`
    padding: 10px;
    text-align: center;
    color: white;
`;

const Home = () => {
    const isLoggedIn = hasSession();

    const content = DINAMICA_ABIERTA()
        ? <Container>
            <Titulo src={titulo} />
            { !isLoggedIn &&  <JuegaYganaTitle /> }
            { !isLoggedIn &&  <LoginComponent /> }
            { isLoggedIn && <ContadorDespensas /> }
            <FooterLogo />
        </Container>
        : <Container>
            <CerradaContainer>{dinamica_cerrada_copy}</CerradaContainer>
        </Container>;

    return content;
};

export default Home;

import React, { useState } from 'react';
import styled from 'styled-components';
import titulo from '../assets/images/play-titulo.svg';
import subtitulo from '../assets/images/premios-mayor-valor.svg';

const Div = styled.div`
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    .animate {
        animation-duration: 0.75s;
        animation-delay: 0.5s;
        animation-name: animate-fade;
        animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
        animation-fill-mode: backwards;
    }

    .animate {
        animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
        animation-duration: 0.3s;
        &.entrance {
            animation-name: animate-entrance;
        }
        &.exit {
            animation: animate-exit 0.3s forwards;
            &.delay-1 {
                animation-delay: 0.5s;
            }
            &.delay-2 {
                animation-delay: 0.8s;
            }
        }
    }

    @keyframes animate-entrance {
        0% {
            opacity: 0;
            transform: scale(0.5, 0.5);
        }

        100% {
            opacity: 1;
            transform: scale(1, 1);
        }
    }

    @keyframes animate-exit {
        0% {
            opacity: 1;
            transform: scale(1, 1);
        }

        100% {
            opacity: 0;
            transform: scale(0.5, 0.5);
        }
    }

    .delay-1 {
        animation-delay: 0.3s;
    }
    .delay-2 {
        animation-delay: 0.6s;
    }
`;
const Titulo = styled.img`
    width: auto;
    max-width: 90vw;
    display: block;
    margin: 5px auto;
`;
const Subtitulo = styled.img`
    width: auto;
    max-width: 55vw;
    display: block;
    margin: -4vw auto 5px auto;
`;

const PlayTitle = ({ gameStarted }) => {
    const [animationEnd, setAnimationEnd] = useState(false);
    let animation = gameStarted ? 'exit' : 'entrance';
    if (gameStarted) {
        setTimeout(() => {
            setAnimationEnd(true);
        }, 2000);
    }

    const content = !animationEnd
        ? (<Div>
            <Titulo className={`animate ${animation} delay-1`} src={titulo} />
            <Subtitulo className={`animate ${animation} delay-2`} src={subtitulo} />
        </Div>)
        : null;

    return content;
};

export default PlayTitle;

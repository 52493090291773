import React, { useState } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import caja from '../assets/images/caja-vacia.png';
import FallingObjects from './FallingObjects';

const Div = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    span {
        color: #fff;
        font-family: Arial;
        font-style: normal;
        display: block;
    }
    .score {
        margin: 0;
        position: absolute;
        right: 10px;
        top: 10px;
        line-height: 1;
        text-align: right;
        left: auto;
    }
`;
const Caja = styled.img`
    width: 200px !important;
    display: block;
    margin: 0;
    position: absolute;
    left: calc(50% - 100px);
    z-index: 99;
    bottom: 75px;
`;
const BoxDiv = styled.div`
    height: 100%;
    display: block;
    margin: 0;
    position: absolute;
    bottom: 0;
    .c-element {
        position: absolute;
        z-index: 0;
        height: auto;
        width: 45px;
    }
`;

const Game = ({ gameEnded, starGame, endGame, prizeEarned }) => {
    const [gameStarted, setGameStarted] = useState(false);
    const [points, setPoints] = useState(0);
    const maxMove = Math.round((window.innerWidth - 200) / 2);
    const handleStart = () => {
        setGameStarted(true);
        starGame(true);
    }

    const content = prizeEarned
        ? null
        : (
            <Div>
                { /* gameStarted && <h1 className="score">{points}</h1> */ }
                { gameStarted && <FallingObjects setPoints={setPoints} endGame={endGame} /> }
                <Draggable axis='x' disabled={gameEnded} bounds={{left: -Math.abs(maxMove), right: maxMove}} onStart={handleStart}>
                    <BoxDiv id='boxDiv'>
                        <Caja src={caja} id='boxEl' />
                    </BoxDiv>
                </Draggable>
            </Div>
        );

    return content;
};                    

export default Game;

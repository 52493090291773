import React from 'react';

import im1 from '../assets/images/falling-images/1.png';
import im2 from '../assets/images/falling-images/2.png';
import im3 from '../assets/images/falling-images/3.png';
import im4 from '../assets/images/falling-images/4.png';
import im5 from '../assets/images/falling-images/5.png';
import im6 from '../assets/images/falling-images/6.png';
import im7 from '../assets/images/falling-images/7.png';
import im8 from '../assets/images/falling-images/8.png';
import im9 from '../assets/images/falling-images/9.png';
import im10 from '../assets/images/falling-images/10.png';
import im11 from '../assets/images/falling-images/11.png';
import im12 from '../assets/images/falling-images/12.png';
import tip1 from '../assets/images/tips/1.svg';
import tip2 from '../assets/images/tips/2.svg';
import tip3 from '../assets/images/tips/3.svg';
import tip4 from '../assets/images/tips/4.svg';
import tip5 from '../assets/images/tips/5.svg';
import tip6 from '../assets/images/tips/6.svg';
import tip7 from '../assets/images/tips/7.svg';
import tip8 from '../assets/images/tips/8.svg';
import sabias1 from '../assets/images/sabias-que/1.svg';
import sabias2 from '../assets/images/sabias-que/2.svg';
import sabias3 from '../assets/images/sabias-que/3.svg';
import sabias4 from '../assets/images/sabias-que/4.svg';
import sabias5 from '../assets/images/sabias-que/5.svg';
import sabias6 from '../assets/images/sabias-que/6.svg';
import sabias7 from '../assets/images/sabias-que/7.svg';
import sabias8 from '../assets/images/sabias-que/8.svg';
import sabias9 from '../assets/images/sabias-que/9.svg';
import sabias10 from '../assets/images/sabias-que/10.svg';
import sabias11 from '../assets/images/sabias-que/11.svg';
import sabias12 from '../assets/images/sabias-que/12.svg';
import sabias13 from '../assets/images/sabias-que/13.svg';
import sabias14 from '../assets/images/sabias-que/14.svg';
import frase1 from '../assets/images/frases/1.svg';
import frase2 from '../assets/images/frases/2.svg';
import frase3 from '../assets/images/frases/3.svg';
import frase4 from '../assets/images/frases/4.svg';
import frase5 from '../assets/images/frases/5.svg';
import frase6 from '../assets/images/frases/6.svg';
import frase7 from '../assets/images/frases/7.svg';
import frase8 from '../assets/images/frases/8.svg';
import frase9 from '../assets/images/frases/9.svg';
import frase10 from '../assets/images/frases/10.svg';
import frase11 from '../assets/images/frases/11.svg';
import frase12 from '../assets/images/frases/12.svg';
import frase13 from '../assets/images/frases/13.svg';
import frase14 from '../assets/images/frases/14.svg';
import { userMocks } from '../core/statics';
import Tip from '../pages/Tip';
import Sabias from '../pages/Sabias';
import FelicesFiestas from '../pages/FelicesFiestas';
import HappyCard from '../pages/HappyCard';
import Kit from '../pages/Kit';

export const getPositions = (el) => {
    const { left, right, top, bottom } = el.getBoundingClientRect();
    return { left, right, top, bottom };
};

/* eslint-disable no-unreachable */
export const returnRandomFallingImg = () => {
    const randomNum = Math.floor(Math.random() * (12 - 0 + 1) + 0);
    switch (randomNum) {
        case 1: return im1; break;
        case 2: return im2; break;
        case 3: return im3; break;
        case 4: return im4; break;
        case 5: return im5; break;
        case 6: return im6; break;
        case 7: return im7; break;
        case 8: return im8; break;
        case 9: return im9; break;
        case 10: return im10; break;
        case 11: return im11; break;
        default: return im12; break;
    }
};

/* eslint-disable no-unreachable */
export const getMockPrice = (tipo) => {
    switch (tipo) {
        case 'Deseo':
            return userMocks.prize_deseo;
            break;
        case 'Sabias':
            return userMocks.prize_sabias;
            break;
        case 'Tip':
            return userMocks.prize_tip;
            break;
        case 'Kit':
            return userMocks.prize_kit;
            break;
        case 'Tarjeta':
            return userMocks.prize_tarjeta;
            break;
        default:
            return null;
            break;
    }
};

/* eslint-disable no-unreachable */
export const getPrizeTemplate = (prizeEarned, setLostPrize, lostPrizeTimeOut) => {
    const { message = {} } = prizeEarned || {};
    const { name, required_information, reward_id } = message;
    const needRegister = required_information === 1 || required_information === '1';

    switch (name) {
        case 'Tip':
            return <Tip />
            break;
        case 'Sabias':
            return <Sabias />
            break;
        case 'Deseo':
            return <FelicesFiestas />
            break;
        case 'Kit':
            return <Kit
                needRegister={needRegister}
                rewardId={reward_id}
                setLostPrize={setLostPrize}
                lostPrizeTimeOut={lostPrizeTimeOut}
            />
            break;
        case 'Tarjeta':
            return <HappyCard
                needRegister={needRegister}
                rewardId={reward_id}
                setLostPrize={setLostPrize}
                lostPrizeTimeOut={lostPrizeTimeOut}
            />
            break;
        default:
            return null;
            break;
    }
};

export const getRandomTip = () => {
    const rnd = Math.floor(Math.random() * 8) + 1;
    switch (rnd) {
        case 1: return tip1; break;
        case 2: return tip2; break;
        case 3: return tip3; break;
        case 4: return tip4; break;
        case 5: return tip5; break;
        case 6: return tip6; break;
        case 7: return tip7; break;
        case 8: return tip8; break;
        default: return null; break;
    }
};

export const getRandomSabias = () => {
    const rnd = Math.floor(Math.random() * 14) + 1;
    switch (rnd) {
        case 1: return sabias1; break;
        case 2: return sabias2; break;
        case 3: return sabias3; break;
        case 4: return sabias4; break;
        case 5: return sabias5; break;
        case 6: return sabias6; break;
        case 7: return sabias7; break;
        case 8: return sabias8; break;
        case 9: return sabias9; break;
        case 10: return sabias10; break;
        case 11: return sabias11; break;
        case 12: return sabias12; break;
        case 13: return sabias13; break;
        case 14: return sabias14; break;
        default: return null; break;
    }
};

export const getRandomFiestas = () => {
    const rnd = Math.floor(Math.random() * 14) + 1;
    switch (rnd) {
        case 1: return frase1; break;
        case 2: return frase2; break;
        case 3: return frase3; break;
        case 4: return frase4; break;
        case 5: return frase5; break;
        case 6: return frase6; break;
        case 7: return frase7; break;
        case 8: return frase8; break;
        case 9: return frase9; break;
        case 10: return frase10; break;
        case 11: return frase11; break;
        case 12: return frase12; break;
        case 13: return frase13; break;
        case 14: return frase14; break;
        default: return null; break;
    }
};

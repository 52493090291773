import React from 'react';
import styled from 'styled-components';
import title from '../assets/images/title_fiestas.svg';
import PrizeHeaders from '../components/PrizeHeaders';
import circulo from '../assets/images/circulo-fondo2x.png';
import { getRandomFiestas } from '../helpers/game';
import FooterLogo from '../components/FooterLogo';

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: space-around;
    position: absolute;
    top: 0;
    left: 0;
`;
const FelicesFiestasContainer = styled.div`
    position: relative;
    margin-bottom: 20vw;
`;
const Circulo = styled.img`
    max-width: 85vw;
    display: block;
    margin: 5px auto;
`;
const FelicesFiestasText = styled.img`
    max-width: 72vw;
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const FelicesFiestas = () => {
    return <Container>
        <PrizeHeaders closeBtn={true} title={title} />
        <FelicesFiestasContainer>
            <Circulo src={circulo} />
            <FelicesFiestasText src={getRandomFiestas()} />
        </FelicesFiestasContainer>
        <FooterLogo />
    </Container>
};

export default FelicesFiestas;

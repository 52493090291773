import { endpoints } from '../core/statics';
import { getApiDomain } from '../helpers';

export const getDespensasDonadas = async() => {
    try {
        const fetchResponse = await fetch(`${getApiDomain()}${endpoints.GET_DESPENSAS}`);
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        console.warn(e);
        return false;
    }
};

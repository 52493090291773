import styled from 'styled-components';
import face from '../assets/images/face-login.svg';
import { useNavigate } from 'react-router-dom';
import { endpoints, LOGIN_MOCK, userMocks, DINAMICA_ABIERTA, dinamica_cerrada_copy } from "../core/statics";
import { getLoginDomain, setSession } from '../helpers';

const FaceLogo = styled.img`
    width: auto;
    max-width: 60vw;
    display: block;
`;
const FaceButton = styled.button`
    padding: 0;
    appearance: none;
    border: 0;
    background: none;
    display: block;
    margin: 15px auto;
`;
const CerradaContainer = styled.div`
    padding: 10px;
    text-align: center;
    color: white;
`;

const LoginComponent = () => {
    const navigate = useNavigate();
    const startSession = () => {
        if (LOGIN_MOCK) {
            setSession(userMocks.fb_login, true);
            navigate('/play');
        } else {
            const urlToBack = `${getLoginDomain()}${endpoints.LOGIN}`;
            window.location.href = urlToBack;
        }
    };

    const content = DINAMICA_ABIERTA()
        ? <>
            <FaceButton onClick={startSession}>
                <FaceLogo src={face} />
            </FaceButton>
        </>
        : <CerradaContainer>{dinamica_cerrada_copy}</CerradaContainer>;

    return content;
};

export default LoginComponent;

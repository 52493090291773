import { storages, LOGIN_COOKIE } from "../core/statics";

/**
 * Regresa el path limpio sin /
 * @returns string
 */
export const mainPath = () => window.location.pathname.replace(/\//g, '');

/**
 * Para devolver si el usuario tiene sesión de face
 * @returns boolean
 */
 export const hasSession = () => {
    const fbCookie = getCookie(LOGIN_COOKIE);
    let sessionData = null;
    try {
        if (fbCookie) {
            let ckData = decodeURI(getCookie(LOGIN_COOKIE));
            ckData = ckData.replace(/%3A/g, ':');
            ckData = ckData.replace(/%40/g, '@');
            ckData = ckData.replace(/%2C/g, ',');
            ckData = ckData.replace(/\+/g, ' ');
            let sData = JSON.parse(ckData);
            if (sData.name && sData.user_id) {
                setSession(ckData);
                sessionData = JSON.parse(ckData);
            }
        } else if (getSession()) {
            sessionData = getSession();
        }
        return sessionData.name && sessionData.user_id
            ? sessionData
            : null;
    } catch (error) {
        console.warn(error, 'Login error')
        return null;
    }
};

/**
 * Para devolver si el usuario tiene sesión de face
 * @returns void
 */
export const setSession = (data, stringify = false) => {
    if (stringify) {
        sessionStorage.setItem(storages.SESSION, JSON.stringify(data));
    } else {
        sessionStorage.setItem(storages.SESSION, data);
    }
};

export const getSession = () => {
    return JSON.parse(sessionStorage.getItem(storages.SESSION));
};

/**
 * Para setear el User data
 * @returns void
 */
export const setUserData = (data) => {
    sessionStorage.setItem(storages.LOGIN_USER_DATA, JSON.stringify(data));
};

/**
 * Para regresar el User data
 * @returns boolean
 */
export const getUserData = () => {
    return sessionStorage.getItem(storages.LOGIN_USER_DATA);
};

/**
 * Para devolver si el intro ha sido o no visto
 * @returns boolean
 */
 export const introSeen = () => {
    return sessionStorage.getItem(storages.INTROSEEN) ? true : false;
};

/**
 * Para devolver si el start ha sido o no visto
 * @returns boolean
 */
 export const startSeen = () => {
    return sessionStorage.getItem(storages.START_SEEN) ? true : false;
};

/**
 * Para obtener una clase distintiva para cada pantalla dependiendo de la ruta
 * @returns string
 */
export const getMainContainerClass = () => {
    const pathname = mainPath();
    let containerClass = '';
    switch (pathname) {
        case '':
            containerClass = 'container-home';
            break;
        case 'intro':
            containerClass = 'container-intro';
            break;
        case 'play':
            containerClass = 'container-play';
            break;
        default:
            containerClass = 'container-notFound';
            break;
    }
    return containerClass;
};

export const needSession = () => {
    const pathname = mainPath();
    const sessionRequired = ['play'];
    return sessionRequired.includes(pathname);
};

export const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
};

/**
 * Regresa el dominio del API
 * @returns string
 */
export const getApiDomain = () => {
    const cHost = window.location.host;
    const urlToBack = cHost.indexOf('lamoderna.com.mx') > -1
        ? 'https://srv-navidad.lamoderna.com.mx'
        : (cHost.indexOf('localhost:3000') > -1
        ? 'https://localhost:3000/api'
        : 'https://api-canasta.heliko.space');
    return urlToBack;
};

/**
 * Regresa el dominio del login
 * @returns string
 */
export const getLoginDomain = () => {
    const cHost = window.location.host;
    const urlToBack = cHost.indexOf('lamoderna.com.mx') > -1
        ? 'https://navidad.lamoderna.com.mx'
        : (cHost.indexOf('localhost:3000') > -1
        ? 'https://localhost:3000/public'
        : 'https://canasta.heliko.space');
    return urlToBack;
};

import styled from 'styled-components';
import { DINAMICA_ABIERTA } from '../core/statics';
import texto from '../assets/images/textos-juega/juega-y-gana.svg';

const Div = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    margin: 20px auto;
    justify-items: center;
    svg {
        display: block;
        @media (max-width: 370px) {
            transform: scale(0.8);
            margin: -2px auto;
        }
    }
`;
const Texto = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;

const JuegaYganaTitle = () => {
    const content = DINAMICA_ABIERTA()
        ? <Texto src={texto} />
        : null;
    return (
        <Div>{content}</Div>
    );
};

export default JuegaYganaTitle;

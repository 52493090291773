import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import perdisteText from '../assets/images/alguien-mas.svg';
import FooterLogo from '../components/FooterLogo';
import close from '../assets/images/close.svg';

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .footerlogo {
        position: absolute;
        bottom: 10px;
        width: 180px;
        left: calc(50% - 90px);
    }
`;
const Close = styled.img`
    width: 42px;
    height: 42px;
    display: block;
    margin: 10px 10px 5px auto;
    position: absolute;
    top: 10px;
    right: 10px;
`;
const PerdisteText = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;

const AlguienMasGano = () => {
    const navigate = useNavigate();

    return <Container>
        <Close src={close} onClick={()=>navigate('/')} />
        <PerdisteText src={perdisteText} />
        <FooterLogo className='footerlogo' />
    </Container>
};

export default AlguienMasGano;

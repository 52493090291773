import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { userRegister } from "../services/user-register";
import FooterLogo from '../components/FooterLogo';
import Game from '../components/Game';
import { setUserData } from '../helpers';
import { LOGIN_MOCK, userMocks } from '../core/statics';
import PlayTitle from '../components/PlayTitle';
import GetPrize from '../components/GetPrize';
import { getPrizeTemplate } from '../helpers/game';
import close from '../assets/images/close.svg';
import loSentimos from '../assets/images/lo-sentimos-perdiste.svg';
import RegresaManana from '../components/RegresaManana';

const Container = styled.div`
    height: 100%;
    overflow: auto;
`;
const Div = styled.div`
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    position: relative;
    min-height: 570px;
    overflow: hidden;

    &.game-ended:not(.obteniendo-premio) {
        height: 100%;
        display: block;
        min-height: 500px;
        overflow: auto;
    }

    > *, #boxDiv, div div {
        width: 100%;
    }

    .footer-container {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    h1 {
        position: absolute;
        top: 50%;
        left: 0;
        text-align: center;
        width: 100%;
        margin-top: -25px;
        color: #fff;
    }

    .gettin-prize {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -25px;
    }
`;
const LoSentimos = styled.img`
    max-width: 82vw;
    display: block;
`;
const Close = styled.img`
    width: 42px;
    height: 42px;
    display: block;
    margin: 10px 10px 5px auto;
    position: absolute;
    top: 0;
    right: 0;
`;

const Play = () => {
    const [lostPrize, setLostPrize] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [gameEnded, setGameEnded] = useState(false);
    const [prizeEarned, setPrizeEarned] = useState(null);
    const [canPlay, setCanPlay] = useState(true);
    let lostPrizeTimeOut = undefined;

    const navigate = useNavigate();
    const addUserData = async () => {
        let userData = await userRegister();
        if (LOGIN_MOCK) {
            userData = userMocks.user_register;
            setUserData(userData);
            setLoggedIn(true);
        } else if (userData) {
            userData = typeof userData === 'string'
                ? JSON.parse(userData)
                : userData;
            setUserData(userData);
            setLoggedIn(true);
        } else {
            console.warn(`wrong userData, ${userData}, ${typeof userData}`);
            navigate('/');
            return;
        }
        if (userData && userData.message) {
            setCanPlay(parseInt(userData.message.number_plays) < 5);
        } else {
            console.warn(`wrong userData, ${userData}, ${typeof userData}`);
            navigate('/');
        }
    };

    if (prizeEarned && !lostPrize) {
        const { message: { name } } = prizeEarned;
        const timeLimitPrizes = ['Tarjeta', 'Kit'];
        if (timeLimitPrizes.includes(name)) {
            const threeMin = 3 * 60 * 1000;
            lostPrizeTimeOut = setTimeout(() => {
                setLostPrize(true);
            }, threeMin);
        }
    }
    const PrizeView = getPrizeTemplate(prizeEarned, setLostPrize, lostPrizeTimeOut);

    useEffect(() => {
        addUserData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let Content = canPlay
        ? <Container>
            <Div className={`game-ended ${!prizeEarned && 'obteniendo-premio'}`}>
                { !loggedIn && <h1>Cargando...</h1> }
                { gameEnded && <GetPrize setPrizeEarned={setPrizeEarned} prizeEarned={prizeEarned} /> }
                { PrizeView }
                { loggedIn && !gameEnded && <div className='footer-container'><FooterLogo /></div> }
            </Div>
        </Container>
        : <RegresaManana />;

    if (!gameEnded && loggedIn && canPlay) {
        Content = <Div style={{minHeight: '500px'}}>
            <PlayTitle gameStarted={gameStarted} />
            <Game starGame={setGameStarted} endGame={setGameEnded} gameEnded={gameEnded} prizeEarned={prizeEarned} />
            <div className='footer-container'>
                <FooterLogo />
            </div>
        </Div>;
    }

    return lostPrize
        ? <Container>
            <Div>
                <Close src={close} onClick={()=>navigate('/')} />
                <LoSentimos src={loSentimos} />
            </Div>
        </Container>
        : Content;
};

export default Play;

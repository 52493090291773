import React, { useState } from 'react';
import styled from 'styled-components';
import regresaDespues from '../assets/images/regresa-despues.svg';
import { getSession, getUserData } from '../helpers';

const Div = styled.div`
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    position: relative;
    min-height: 570px;
    overflow: hidden;
`;
const RegresaDespues = styled.img`
    max-width: 82vw;
    display: block;
`;
const Button = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: 0;
    border-radius: 0;
    color: red;
    font-weight: bold;
`;
const InfoDiv = styled.div`
    position: absolute;
    background: white;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 15px;
    width: 60%;
    font-family: Tahoma, Arial, sans-serif;
    font-weight: normal;
    color: #000;
    font-style: normal;
    font-size: 12px;
    overflow-wrap: break-word;
    overflow: hidden;
`;

const RegresaManana = () => {
    const [tapsClicked, setTapsClicked] = useState(0);

    if (tapsClicked >= 5) {
        setTimeout(() => {
            setTapsClicked(0)
        }, 5000);
    }

    const info = tapsClicked >= 5
        ? <InfoDiv>
            {JSON.stringify(getSession())}
            { ' || ' }
            {getUserData()}
        </InfoDiv>
        : null;

    return <Div onClick={() => {
        if (tapsClicked < 5) {
            setTapsClicked(tapsClicked + 1);
        }
    }}>
        <RegresaDespues src={regresaDespues} />
        {info}
    </Div>;
};

export default RegresaManana;

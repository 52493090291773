import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import title from '../assets/images/premios-chidos/title-reclama.svg';
import labelDireccion from '../assets/images/premios-chidos/label-direccion.svg';
import labelEmail from '../assets/images/premios-chidos/label-email.svg';
import labelNombre from '../assets/images/premios-chidos/label-nombre.svg';
import labelPhone from '../assets/images/premios-chidos/label-phone.svg';
import labelCP from '../assets/images/premios-chidos/label-cp.svg';
import textoTimeWarning from '../assets/images/2-min.svg';
import btnEnviar from '../assets/images/premios-chidos/btn-enviar.svg';
import { registerWinner } from '../services/register-winner';

const Container = styled.div`
    display: flex;
    height: calc(100% - 50px);
    min-height: 553px;
    flex-wrap: wrap;
    align-content: space-around;
    top: 0;
    left: 0;
    flex-grow: 1;
    &.tipo-tarjeta {
        min-height: 500px;
    }
`;
const FieldsContainer = styled.div`
    position: relative;
    padding: 0 15px;
    max-width: 70vw;
    margin: 5px auto;

    label:not(:last-child) {
        display: block;
        margin-bottom: 20px;
    }

    div {
        display: block;
        appearance: none;
        border-radius: 20px;
        border: 2px solid #fff;
        background: none;
        position: relative;
        margin: 8px 0 0 0;
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: calc(100% + 4px);
            border-radius: 20px;
            background: red;
            z-index: -1;
            top: 2px;
            left: 6px;
        }
    }

    input {
        display: block;
        appearance: none;
        border-radius: 20px;
        background: none;
        width: calc(100% - 30px);
        border: 0;
        height: 38px;
        padding: 0 15px;
        color: #fff;
        font-weight: bold;
        outline: 0;
        &:hover, &:focus, &:active {
            outline: 0;
        }
        &.invalid {
            color: #f8b83b;
        }
    }

    &.kit-container {
        input {
            @media (max-height: 799px) {
                height: 34px;
            }
            @media (max-height: 699px) {
                height: 24px;
            }
        }
    }
`;
const Title = styled.img`
    max-width: 76vw;
    display: block;
    margin: 5px auto;
`;
const LabelText = styled.img`
    max-width: 76vw;
    display: block;
    margin: 0 0 4px 0;
`;
const BtnEnviar = styled.button`
    max-width: 76vw;
    display: block;
    margin: 5px auto;
    appearance: none;
    border: 0;
    padding: 0;
    background: none;
`;
const BtnImage = styled.img`
    display: block;
`;
const TextoTimeWarning = styled.img`
    max-width: 50vw;
    display: block;
    margin: 0 auto;
`;

const RegisterForm = ({ tipo, rewardId, setIsRegistered }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const isValidEmail = (email) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    const isNumber = (value) => /^\d*\.?\d*$/.test(value);
    const navigate = useNavigate();

    const setEmail = ({ target }) => {
        const newFormData = JSON.parse(JSON.stringify(formData));
        newFormData.email = { value: target.value, valid: isValidEmail(target.value) };
        setFormData(newFormData);
    };
    const setName = ({ target }) => {
        const newFormData = JSON.parse(JSON.stringify(formData));
        newFormData.name = { value: target.value, valid: target.value && target.value.length >= 5 };
        setFormData(newFormData);
    };
    const setPhone = ({ target }) => {
        if (isNumber(target.value) && target.value.length <= 10) {
            const newFormData = JSON.parse(JSON.stringify(formData));
            newFormData.phone = { value: target.value, valid: target.value && target.value.length === 10 };
            setFormData(newFormData);
        }
    };
    const setCP = ({ target }) => {
        if (isNumber(target.value) && target.value.length <= 5) {
            const newFormData = JSON.parse(JSON.stringify(formData));
            newFormData.cp = { value: target.value, valid: target.value && target.value.length === 5 };
            setFormData(newFormData);
        }
    };
    const setDireccion = ({ target }) => {
        const newFormData = JSON.parse(JSON.stringify(formData));
        newFormData.direccion = { value: target.value, valid: target.value && target.value.length >= 5 };
        setFormData(newFormData);
    };
    const validateForm = async () => {
        const { name = {}, phone = {}, email = {}, direccion = {}, cp = {} } = formData;
        const isCard = tipo === 'tarjeta';
        const addressValidated = isCard || direccion.valid;
        const cpValidated = isCard || cp.valid;
        if (name.valid && phone.valid && email.valid && addressValidated && cpValidated) {
            setIsLoading(true);
            const result = await registerWinner({
                reward_list_id: rewardId,
                full_name: name.value,
                email: email.value,
                phone_number: phone.value,
                address: direccion.value || undefined,
                code_zip: cp.value ? '' + cp.value + '' : undefined,
            });
            if (result) {
                setIsRegistered(true);
            } else {
                console.warn('Error whe tryng to register a winner, ', result);
                navigate('/lost-prize');
            }
        } else {
            alert('Todos los campos son requeridos');
        }
    };

    const Fields = tipo === 'tarjeta'
        ? <FieldsContainer className='tarjeta-container'>
            <label>
                <LabelText src={labelNombre} />
                <div>
                    <input
                        type='text'
                        name='name'
                        maxLength={80}
                        value={formData.name?.value || ''}
                        className={ !formData.name?.valid && formData.name?.value.length ? 'invalid' : '' }
                        onChange={setName}
                        autoComplete='off'
                    />
                </div>
            </label>
            <label>
                <LabelText src={labelPhone} />
                <div>
                    <input
                        type='text'
                        name='phone'
                        maxLength={80}
                        value={formData.phone?.value || ''}
                        className={ !formData.phone?.valid && formData.phone?.value.length ? 'invalid' : '' }
                        onChange={setPhone}
                        autoComplete='off'
                    />
                </div>
            </label>
            <label>
                <LabelText src={labelEmail} />
                <div>
                    <input
                        type='text'
                        name='email'
                        maxLength={80}
                        value={formData.email?.value || ''}
                        className={ !formData.email?.valid && formData.email?.value.length ? 'invalid' : '' }
                        onChange={setEmail}
                        autoComplete='off'
                    />
                </div>
            </label>
        </FieldsContainer>
        : <FieldsContainer className='kit-container'>
            <label>
                <LabelText src={labelNombre} />
                <div>
                    <input
                        type='text'
                        name='name'
                        maxLength={80}
                        value={formData.name?.value || ''}
                        className={ !formData.name?.valid && formData.name?.value.length ? 'invalid' : '' }
                        onChange={setName}
                        autoComplete='off'
                    />
                </div>
            </label>
            <label>
                <LabelText src={labelPhone} />
                <div>
                    <input
                        type='text'
                        name='phone'
                        maxLength={80}
                        value={formData.phone?.value || ''}
                        className={ !formData.phone?.valid && formData.phone?.value.length ? 'invalid' : '' }
                        onChange={setPhone}
                        autoComplete='off'
                    />
                </div>
            </label>
            <label>
                <LabelText src={labelEmail} />
                <div>
                    <input
                        type='text'
                        name='email'
                        maxLength={80}
                        value={formData.email?.value || ''}
                        className={ !formData.email?.valid && formData.email?.value.length ? 'invalid' : '' }
                        onChange={setEmail}
                        autoComplete='off'
                    />
                </div>
            </label>
            <label>
                <LabelText src={labelDireccion} />
                <div>
                    <input
                        type='text'
                        name='address'
                        maxLength={80}
                        value={formData.direccion?.value || ''}
                        className={ !formData.direccion?.valid && formData.direccion?.value.length ? 'invalid' : '' }
                        onChange={setDireccion}
                        autoComplete='off'
                    />
                </div>
            </label>
            <label>
                <LabelText src={labelCP} />
                <div>
                    <input
                        type='text'
                        name='cp'
                        maxLength={5}
                        value={formData.cp?.value || ''}
                        className={ !formData.cp?.valid && formData.cp?.value.length ? 'invalid' : '' }
                        onChange={setCP}
                        autoComplete='off'
                    />
                </div>
            </label>
        </FieldsContainer>;

    return <Container className={`${tipo === 'tarjeta' && 'tipo-tarjeta'}`}>
        <Title src={title} />
        <TextoTimeWarning src={textoTimeWarning} />
        { Fields }
        <BtnEnviar type='button' disabled={isLoading}>
            <BtnImage src={btnEnviar} onClick={validateForm} />
        </BtnEnviar>
    </Container>;
};

export default RegisterForm;

import React, { useState } from 'react';
import styled from 'styled-components';
import title from '../assets/images/premios-chidos/title-felicidades.svg';
import PrizeHeaders from '../components/PrizeHeaders';
import subtitle from '../assets/images/estas-a-punto-tarjeta.svg';
import partners from '../assets/images/premios-chidos/happy-partners.png';
import tarjeta from '../assets/images/premios-chidos/happy-card.png';
import btnRegister from '../assets/images/premios-chidos/btn-reclama.svg';
import RegisterForm from '../components/RegisterForm';
import DisfrutaPremio from '../components/DisfrutaPremio';
import FooterLogo from '../components/FooterLogo';

const Container = styled.div`
    display: flex;
    min-height: 100%;
    flex-wrap: wrap;
    align-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    &.disfruta-premio {
        flex-flow: column;
    }
    .footer-container {
        position: relative !important;
    }
`;
const Subtitle = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;
const HappyCardContainer = styled.div`
    position: relative;
`;
const Tarjeta = styled.img`
    max-width: 60vw;
    display: block;
    margin: 5px auto;
`;
const Partners = styled.img`
    max-width: 73vw;
    display: block;
    margin: 5px auto;
`;
const BtnRegister = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;
let registerInterval = undefined;

const HappyCard = ({ needRegister, rewardId, setLostPrize, lostPrizeTimeOut }) => {
    const [isInRegister, setIsInRegister] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const RegisterContent = isRegistered
        ? <DisfrutaPremio />
        : <RegisterForm tipo='tarjeta' rewardId={rewardId} setIsRegistered={setIsRegistered} />;

    if (!isRegistered && isInRegister) {
        if (lostPrizeTimeOut) {
            clearInterval(lostPrizeTimeOut);
        }
        const twoMin = 2 * 60 * 1000;
        registerInterval = setTimeout(() => {
            setLostPrize(true);
        }, twoMin);
    } else if (isRegistered && registerInterval) {
        clearInterval(registerInterval);
    }

    return isInRegister
        ? <Container className='disfruta-premio'>
            { RegisterContent }
            <div className='footer-container'>
                <FooterLogo />
            </div>
        </Container>
        : <Container>
            <PrizeHeaders title={title} />
            <div>
                <Subtitle src={subtitle} />
            </div>
            <HappyCardContainer>
                <Tarjeta src={tarjeta} />
                <Partners src={partners} />
            </HappyCardContainer>
            { needRegister && <BtnRegister src={btnRegister} onClick={()=>setIsInRegister(true)} /> }
            <div className='footer-container'>
                <FooterLogo />
            </div>
        </Container>;
};

export default HappyCard;

import { endpoints } from '../core/statics';
import { getApiDomain, hasSession } from '../helpers';

export const userRegister = async() => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(hasSession())
    };
    try {
        const fetchResponse = await fetch(`${getApiDomain()}${endpoints.REGISTERLOGIN}`, settings);
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        console.warn(e);
        return false;
    }
};

import React, { useState } from 'react';
import styled from 'styled-components';
import title from '../assets/images/premios-chidos/title-felicidades.svg';
import PrizeHeaders from '../components/PrizeHeaders';
import subtitle from '../assets/images/premios-chidos/text-estas-a-punto-kit.svg';
import sopas from '../assets/images/premios-chidos/sopas.png';
import btnRegister from '../assets/images/premios-chidos/btn-reclama.svg';
import RegisterForm from '../components/RegisterForm';
import DisfrutaPremio from '../components/DisfrutaPremio';
import FooterLogo from '../components/FooterLogo';

const Container = styled.div`
    display: flex;
    min-height: 100%;
    flex-wrap: wrap;
    align-content: space-between;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    &.disfruta-premio {
        flex-flow: column;
    }
    .footer-container {
        position: relative !important;
    }
`;
const Subtitle = styled.img`
    max-width: 89vw;
    display: block;
    margin: 5px auto;
`;
const KitContainer = styled.div`
    position: relative;
`;
const Sopas = styled.img`
    max-width: 85vw;
    display: block;
    margin: 5px auto;
`;
const BtnRegister = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;
let registerInterval = undefined;

const Kit = ({ needRegister, rewardId, setLostPrize, lostPrizeTimeOut }) => {
    const [isInRegister, setIsInRegister] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const RegisterContent = isRegistered
        ? <DisfrutaPremio />
        : <RegisterForm tipo='kit' rewardId={rewardId} setIsRegistered={setIsRegistered} />;

    if (!isRegistered && isInRegister) {
        if (lostPrizeTimeOut) {
            clearInterval(lostPrizeTimeOut);
        }
        const twoMin = 2 * 60 * 1000;
        registerInterval = setTimeout(() => {
            setLostPrize(true);
        }, twoMin);
    } else if (isRegistered && registerInterval) {
        clearInterval(registerInterval);
    }

    return isInRegister
        ? <Container className='disfruta-premio'>
            { RegisterContent }
            <div className='footer-container'>
                <FooterLogo />
            </div>
        </Container>
        : <Container>
            <PrizeHeaders title={title} />
            <Subtitle src={subtitle} />
            <KitContainer>
                <Sopas src={sopas} />
            </KitContainer>
            { needRegister && <BtnRegister src={btnRegister} onClick={()=>setIsInRegister(true)} /> }
            <div className='footer-container'>
                <FooterLogo />
            </div>
        </Container>;
};

export default Kit;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import close from '../assets/images/close.svg';

const Header = styled.div`
    display: block;
    margin-bottom: 10px;
    position: relative;
`;
const Title = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;
const Close = styled.img`
    width: 42px;
    height: 42px;
    display: block;
    margin: 10px 10px 5px auto;
`;

const PrizeHeaders = ({ title, closeBtn }) => {
    const navigate = useNavigate();

    return <Header>
        { closeBtn && <Close src={close} onClick={()=>navigate('/')} /> }
        <Title src={title} />
    </Header>
};

export default PrizeHeaders;

import React from 'react';
import { Navigate } from "react-router-dom";
import {
    getMainContainerClass,
    hasSession,
    introSeen,
    mainPath,
    needSession,
    startSeen
} from '../helpers';
import { storages } from './statics';
import './GuardedRoute.css';

const GuardedRoute = ({ component: Component, isPlay, ...rest }) => {
    const containerClass = getMainContainerClass();
    let navigateTo = introSeen() ? '/' : '/intro';
    let needsRedirect = (!introSeen() && mainPath() !== 'intro') || (!hasSession() && needSession());

    if (isPlay && !startSeen() && introSeen()) {
        sessionStorage.setItem(storages.START_SEEN, true);
        navigateTo = '/';
        needsRedirect = true;
    }

    return needsRedirect
        ? <Navigate to={navigateTo} />
        : <div className={`main-container ${containerClass}`}>
            <Component {...rest} />
        </div>;
};

export default GuardedRoute;

import React from 'react';
import styled from 'styled-components';
import title from '../assets/images/title_tip.svg';
import PrizeHeaders from '../components/PrizeHeaders';
import tipFondo from '../assets/images/tip_fondo_2x.png';
import { getRandomTip } from '../helpers/game';
import FooterLogo from '../components/FooterLogo';

const Container = styled.div`
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: space-around;
    position: absolute;
    top: 0;
    left: 0;
`;
const TipContainer = styled.div`
    position: relative;
`;
const TipFondo = styled.img`
    max-width: 67vw;
    display: block;
    margin: 5px auto;
`;
const TipText = styled.img`
    max-width: 67vw;
    display: block;
    margin: 0;
    position: absolute;
    top: 12vw;
    left: calc(50% + 5vw);
    transform: translate(-50%, 0);
`;

const Tip = () => {
    return <Container>
        <PrizeHeaders closeBtn={true} title={title} />
        <TipContainer>
            <TipFondo src={tipFondo} />
            <TipText src={getRandomTip()} />
        </TipContainer>
        <FooterLogo />
    </Container>
};

export default Tip;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import botonJugar from '../assets/images/empieza-a-jugar.svg';
import { getDespensasDonadas } from '../services/contador-despensas';
import { LOGIN_MOCK } from '../core/statics';
import titulo from '../assets/images/por-cada-ganador-pasta-donada.svg';
import donadasImg from '../assets/images/pastas-donadas.svg';
import kiloAyuda from '../assets/images/logo-ayuda.png';

const Div = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    margin: 10px auto;
    justify-items: center;
    .mt-30 {
        margin-top: 42px;
    }
    .stroke-border {
        font-size: 29px;
        line-height: 1;
        margin: 0;
        text-align: center;
        text-shadow:2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
                    1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
    }
`;
const BotonJugar = styled.img`
    max-width: 68vw;
    display: block;
    margin: 15px auto;
`;
const Titulo = styled.img`
    width: auto;
    max-width: 90vw;
    display: block;
    margin: 5px auto;
`;
const Donadas = styled.img`
    width: auto;
    max-width: 90vw;
    display: block;
    margin: 5px auto;
`;
const KiloAyuda = styled.img`
    width: auto;
    max-width: 29vw;
    display: block;
    margin: 10px auto;
`;

const ContadorDespensas = () => {
    const [donadas, setDonadas] = useState('......');
    const getDonadas = async () => {
        const donas = await getDespensasDonadas();
        if (donas && donas.message) {
            setDonadas(donas.message);
        } else if (LOGIN_MOCK) {
            setDonadas((Math.floor(Math.random() * 9000) + 1).toString().padStart(5, '0'));
        } else {
            console.warn(`wrong despensas donadas data ${donas}, ${typeof donas}`);
        }
    }

    useEffect(() => {
        getDonadas();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Div>
            <Titulo src={titulo} />
            <KiloAyuda src={kiloAyuda} />
            <div>
                <h4 className='stroke-border'>{donadas}</h4>
                <Donadas src={donadasImg} />
            </div>
            <Link to='/play'>
                <BotonJugar src={botonJugar} />
            </Link>
        </Div>
    );
};

export default ContadorDespensas;

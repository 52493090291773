import styled from 'styled-components';
import logo from '../assets/images/logo-intro.png';

const Logo = styled.img`
    width: auto;
    max-width: 50vw;
    display: block;
    margin: 15px auto;
`;
const FooterLogo = ({ className }) => {
    return <Logo src={logo} className={className} />;
};
  
export default FooterLogo;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import title from '../assets/images/titulo-home.svg';
import textDatos from '../assets/images/premios-chidos/text-datos-confirmados.svg';
import textDisfruta from '../assets/images/premios-chidos/text-disfruta.svg';
import btnBack from '../assets/images/premios-chidos/btn-volver-jugar.svg';
import ayduaste from '../assets/images/ganando-ayudaste.svg';
import kiloAyuda from '../assets/images/logo-ayuda.png';

const Container = styled.div`
    display: flex;
    height: calc(100% - 50px);
    flex-wrap: wrap;
    align-content: space-around;
    flex-grow: 1;
`;
const Title = styled.img`
    max-width: 89vw;
    display: block;
    margin: 5px auto;
`;
const Ayduaste = styled.img`
    max-width: 89vw;
    display: block;
    margin: 15px auto 5px auto;
`;
const KiloAyuda = styled.img`
    width: auto;
    max-width: 35vw;
    display: block;
    margin: 10px auto 0 auto;
`;
const TextDatos = styled.img`
    max-width: 69vw;
    display: block;
    margin: 5px auto;
`;
const TetxtDisfruta = styled.img`
    max-width: 69vw;
    display: block;
    margin: 5px auto;
`;
const BtnBack = styled.img`
    max-width: 69vw;
    display: block;
    margin: 5px auto;
`;
const TextContainer = styled.div`
    margin: 10px 0;
`;

const DisfrutaPremio = () => {
    const navigate = useNavigate();

    return <Container>
        <Title src={title} />
        <TextContainer>
            <TextDatos src={textDatos} />
            <TetxtDisfruta src={textDisfruta} />
            <Ayduaste src={ayduaste} />
            <KiloAyuda src={kiloAyuda} />
        </TextContainer>
        <BtnBack src={btnBack} onClick={()=>navigate('/')} />
    </Container>;
};

export default DisfrutaPremio;

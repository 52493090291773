import React from 'react';
import './text.css';

export const StrokeText = ({ fontSize, text, w, h, y }) => <svg className='stroked' xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox={`0 0 ${w} ${h}`}>
    <text x="3" y={y} fontSize={`${fontSize}px`}>{text}</text>
</svg>;

export const StrokeTextCentered = ({ className='', fontSize, text, w, h, y='50%' }) => <svg className={`stroked ${className}`} xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox={`0 0 ${w} ${h}`}>
    <text x="50%" y={y} dominantBaseline="middle" textAnchor="middle" fontSize={`${fontSize}px`}>{text}</text>
</svg>;

export const StrokeTextRight = ({ className, fontSize, text, w, h, y='50%' }) => <svg className={`stroked ${className}`} xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox={`0 0 ${w} ${h}`}>
    <text x="100%" y={y} dominantBaseline="middle" textAnchor="end" fontSize={`${fontSize}px`}>{text}</text>
</svg>;


/**
 * Para mockear o no el login
 */
export const LOGIN_MOCK = window.location.hostname === 'localhost';

/**
 * Cookie que regresa el login
 */
export const LOGIN_COOKIE = 'fb_login_data';

/**
 * Para cerrar o abrir dinámica
 */
export const DINAMICA_ABIERTA = () => {
    const currentTime = new Date();
    const newYear = new Date('2024-01-01T00:00:00.000-06:00');
    return currentTime < newYear;
};
export const dinamica_cerrada_copy = 'La dinámica ha terminado, espera el siguiente concurso';

export const storages = {
    INTROSEEN: 'mdna-introseen',
    START_SEEN: 'mdna-startseen',
    SESSION: 'mdna-session',
    LOGIN_USER_DATA: 'mdna-ldata',
};

export const URL_TYCS = 'https://www.lamoderna.com.mx/Promos/TyC_LM_FB_ArmaLaPosada_Dic2023.pdf';

/**
 * Lista de endpoints
 */
export const endpoints = {
    LOGIN: '/login.php',
    REGISTERLOGIN: '/api/user-login',
    GET_DESPENSAS: '/api/get-total-plays',
    GET_PRIZE: '/api/get-reward/{user_id}',
    WINNER_REGISTER: '/api/register-form-winner'
};

/**
 * Mocks de usuarios
 */
export const userMocks = {
    fb_login: {
        email: 'jcs666a2000@gmail.com',
        user_id: '6039716359374525',
        name: 'Julio Cesar Sandoval'
    },
    user_register: {
        code: 200,
        message: {
            user: {
                id: 3,
                name_facebook: 'Julio Cesar Sandoval',
                email_facebook: 'jcs666a2000@gmail.com',
                id_facebook: '6039716359374525',
                last_connection: '2022-11-27 18:39:51',
                status: 'activo',
                created_at: '2022-11-17T06:17:43.000000Z',
                updated_at: '2022-11-27T18:39:52.000000Z'
            },
            play: true,
            number_plays: 5
        }
    },
    prize_deseo: {
        code: 200,
        message: {
            reward_id: 3,
            name: 'Deseo',
            required_information: 0
        }
    },
    prize_sabias: {
        code: 200,
        message: {
            reward_id: 2,
            name: 'Sabias',
            required_information: 0
        }
    },
    prize_tip: {
        code: 200,
        message: {
            reward_id: 1,
            name: 'Tip',
            required_information: 0
        }
    },
    prize_kit: {
        code: 200,
        message: {
            reward_id: 5,
            name: 'Kit',
            required_information: 1
        }
    },
    prize_tarjeta: {
        code: 200,
        message: {
            reward_id: 4,
            name: 'Tarjeta',
            required_information: 1
        }
    },
    winner_registered: {
        code: 200,
        message: 'Successful insert'
    }
};

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LOGIN_MOCK } from '../core/statics';
import { getMockPrice } from '../helpers/game';
import obteniendoText from '../assets/images/cargando-premio.svg';
import { getPrize } from '../services/get-prize';

const ObteniendoText = styled.img`
    width: auto;
    max-width: 60vw;
    display: block;
    position: absolute;
    top: 50%;
`;

const GetPrize = ({ setPrizeEarned, prizeEarned }) => {
    const navigate = useNavigate();
    const getMyPrize = async () => {
        if (!prizeEarned) {
            const prize = await getPrize();
            if (LOGIN_MOCK) {
                setPrizeEarned(getMockPrice('Tarjeta'));
            } else if (prize) {
                setPrizeEarned(prize);
            } else {
                console.warn(`wrong get-reward, ${prize}, ${typeof prize}`);
                navigate('/');
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            getMyPrize();
        }, 2000);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return !prizeEarned
        ? <ObteniendoText src={obteniendoText} />
        : null;
};

export default GetPrize;

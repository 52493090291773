import { endpoints } from '../core/statics';
import { getApiDomain, getUserData } from '../helpers';

export const getPrize = async() => {
    try {
        const userData = JSON.parse(getUserData());
        const endpoint = endpoints.GET_PRIZE.replace('{user_id}', userData.message.user.id_facebook);
        const fetchResponse = await fetch(`${getApiDomain()}${endpoint}`);
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        console.warn(e);
        return false;
    }
};

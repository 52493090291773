import { endpoints, LOGIN_MOCK, userMocks } from '../core/statics';
import { getApiDomain, hasSession } from '../helpers';

export const registerWinner = async(formdata) => {
    const session = hasSession();
    if (session) {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: session.user_id,
                ...formdata
            })
        };
        try {
            const fetchResponse = await fetch(`${getApiDomain()}${endpoints.WINNER_REGISTER}`, settings);
            const data = await fetchResponse.json();
            return data;
        } catch (e) {
            console.warn(e);
            return LOGIN_MOCK
                ? userMocks.winner_registered
                : false;
        }
    } else {
        console.warn('The client does not have a session, ', session);
        return false;
    }
};

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LOGIN_MOCK } from '../core/statics';
import { getPositions, returnRandomFallingImg } from '../helpers/game';

const Div = styled.div`
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;

    .f-element {
        top: -50px;
        position: absolute;
        height: auto;
        width: 45px;
    }
`;

const FallingObjects = ({ endGame, setPoints }) => {
    let interval;
    let ponits = 0;

    const createElement = () => {
        const element = document.createElement('img');
        element.src = returnRandomFallingImg();
        element.className = 'f-element';
        return element;
    };

    const fallElement = (element, screenH, leftPos, topPos=-50) => {
        element.style.left = `${leftPos}px`;
        const {
            left: bLeft,
            right: bRight,
            top: bTop,
            bottom: bBottom
        } = getPositions(document.getElementById('boxEl'));
        const {
            left: eLeft,
            right: eRight,
            top: eTop,
            bottom: eBottom
        } = getPositions(element);
        const randomTop = Math.floor(Math.random() * (38 - 0 + 10) + 0);

        if (eLeft > bLeft && eRight < bRight && (eTop + randomTop) > bTop && eBottom < bBottom) {
            const { left: dLeft } = getPositions(document.getElementById('boxDiv'));
            const newLeft = (eLeft + (dLeft * -1));
            element.style.left = `${newLeft}px`;
            element.classList.add('c-element');
            element.classList.remove('f-element');
            document.getElementById('boxDiv').appendChild(element);
            ponits++;
            setPoints(ponits);
        } else if (topPos <= (screenH + 50)) {
            setTimeout(() => {
                topPos = topPos + 13;
                element.style.top = `${topPos}px`;
                fallElement(element, screenH, leftPos, eTop);
            }, 9);
        } else {
            element.remove();
        }
    };

    const dropElements = () => {
        const screenH = window.innerHeight;
        const screenW = window.innerWidth;
        const gameTime = LOGIN_MOCK ? 5000 : 40000;

        interval = setInterval(() => {
            const els = document.getElementsByClassName('f-element').length;
            if (els < 4) {
                const left = Math.floor(Math.random() * ((screenW - 50) - 0 + 1) + 0);
                const element = createElement();
                const container = document.getElementById('f-container');
                container.appendChild(element);
                fallElement(element, screenH, left);
            }
        }, 350);

        setTimeout(() => {
            clearInterval(interval);
            setTimeout(() => {
                endGame(true);
            }, 1000);
        }, gameTime);
    };

    useEffect(() => {
        dropElements();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Div id='f-container'>
        </Div>
    );
};

export default FallingObjects;

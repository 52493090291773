
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GuardedRoute from './core/GuardedRoute';
import { DINAMICA_ABIERTA } from './core/statics';
import Home from "./pages/Home";
import Intro from "./pages/Intro";
import Play from "./pages/Play";
import NoPage from "./pages/NoPage";
import AlguienMasGano from './pages/AlguienMasGano';
import './App.css';
import { StrokeTextCentered } from './helpers/text-tools';

function App() {
  const cerradaClass = DINAMICA_ABIERTA()
    ? ''
    : 'dinamica-cerrada';
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<GuardedRoute component={Home} />} />
        <Route path="intro" element={<GuardedRoute component={Intro} />} />
        <Route path="play" element={<GuardedRoute component={Play} isPlay={true} />} />
        <Route path="lost-prize" element={<GuardedRoute component={AlguienMasGano} />} />
        <Route path="*" element={<GuardedRoute component={NoPage} />} />
      </Routes>
      <div className={`no-landscape ${cerradaClass}`}>
        <div className='centered-leyend'>
          <StrokeTextCentered text='Por favor gira tu telefono' fontSize='30' w='460' h='42' />
          <StrokeTextCentered text='en posición vertical.' fontSize='30' w='460' h='42' />
        </div>
      </div>
      <div className={`no-desktop ${cerradaClass}`}>
        <div className='centered-leyend'>
          <StrokeTextCentered text='Visítanos desde' fontSize='30' w='460' h='42' />
          <StrokeTextCentered text='tu smartphone.' fontSize='30' w='460' h='42' />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
